export const COLORS_PRIMARY = {
  DARK_BLUE: "#1f2754",
  GRAY: "#a8a8a8",
  WHITE: "#fff",
  LIGHT_BLUE: "#1497d7",
  BACKGROUND_BLUE: "#e7f6fe",
  BACKGROUND_BLUE_TWO: "#ade3fe",
  BLACK: "#010101",
  BEGINNER: "#17ce2f",
  INTERMEDIATE: "#21a4e0",
  EXPERT: "#ff4646",
  LIGHT_BLUE_SOFT: "#e7f6fe",
  BOX_SHADOW: "0px 10px 8px #bbb",
  INTERVIEW_BACKGROUND: "#F5F5F5",
}
