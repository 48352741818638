import {
  ButtonGroup,
  Container,
  IconButton,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react"
import * as React from "react"
import { FaGithub, FaLinkedin, FaTwitter, FaDiscord } from "react-icons/fa"
import { Logo } from "./Logo"
import vomuxLogo from "../../../images/vomux_logo.svg"

export const Footer = () => (
  <Container as="footer" role="contentinfo" py={{ base: "12", md: "16" }}>
    <Stack spacing={{ base: "4", md: "5" }}>
      <Stack justify="space-between" direction="row" align="center">
        <Image h="56px" src={vomuxLogo} />
        <ButtonGroup variant="ghost">
          <IconButton
            as="a"
            href="#"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
            onClick={() => {
              typeof window !== "undefined"
                ? window
                    .open("https://www.linkedin.com/company/vomux", "_blank")
                    .focus()
                : undefined
            }}
          />
          {/* <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} /> */}
          <IconButton
            as="a"
            href="#"
            aria-label="Twitter"
            icon={<FaTwitter fontSize="1.25rem" />}
            onClick={() => {
              typeof window !== "undefined"
                ? window.open("https://twitter.com/VomuxInc", "_blank").focus()
                : undefined
            }}
          />
          <IconButton
            as="a"
            href="#"
            aria-label="Discord"
            icon={<FaDiscord fontSize="1.25rem" />}
            onClick={() => {
              typeof window !== "undefined"
                ? window.open("https://discord.gg/c3hzc2e2DF", "_blank").focus()
                : undefined
            }}
          />
        </ButtonGroup>
      </Stack>
      <Text fontSize="sm" color="subtle">
        &copy; {new Date().getFullYear()} Vomux, Inc. All rights reserved.
      </Text>
    </Stack>
  </Container>
)
