import React from "react"
import { Box } from "@chakra-ui/react"
import "../index.css"
import { LandingPage } from "../components/landing_page"
import Seo from "../components/seo"

const IndexPage = () => (
  <Box>
    <Seo title="Interview and hire engineers" />
    <LandingPage />
  </Box>
)

export default IndexPage
