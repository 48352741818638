import {
  Box,
  BoxProps,
  Button,
  createIcon,
  Divider,
  Heading,
  List,
  ListItem,
  ListItemProps,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react"
import * as React from "react"

const CheckIcon = createIcon({
  viewBox: "0 0 17 12",
  d: "M0 5.82857L1.64571 4.11429L5.48571 7.2L14.8114 0L16.4571 1.71429L5.48571 12L0 5.82857Z",
})

const PricingDetail = (props: ListItemProps & { iconColor: string }) => {
  const { children, iconColor, ...rest } = props
  return (
    <ListItem
      display="flex"
      alignItems="flex-start"
      fontWeight="medium"
      maxW="260px"
      {...rest}
    >
      <CheckIcon marginEnd="3" mt="1" color={iconColor} />
      <Text as="span" display="inline-block">
        {children}
      </Text>
    </ListItem>
  )
}

interface PricingCardProps extends BoxProps {
  features: string[]
  name: string
  duration: string
  extras: string
  description: string
  price: string
  priceStrike: boolean
  onClick?: () => void
  colorScheme: string
  buttonCopy: string
}

export const PricingCard = (props: PricingCardProps) => {
  const {
    features,
    name,
    description,
    duration,
    price,
    priceStrike,
    extras,
    onClick,
    colorScheme: c,
    ...rest
  } = props

  return (
    <Box p={{ base: "10", md: "16" }} {...rest}>
      <Heading>{name}</Heading>
      <Divider
        opacity={1}
        borderWidth="2px"
        borderColor={mode(`${c}.500`, `${c}.200`)}
        my="6"
        w="56px"
      />

      <Text maxW="280px" fontSize="lg">
        {description}
      </Text>

      <Box mt="2">
        <Text fontSize={{ base: "5xl", md: "6xl" }} fontWeight="extrabold">
          {(() => {
            if (priceStrike) {
              return <s>{price}</s>
            }
            return price
          })()}
          {priceStrike ? " Free" : undefined}
        </Text>
        <Text casing="uppercase" fontSize="lg" fontWeight="bold">
          {duration}
        </Text>
        <Text mt="2" fontSize="xl" color={mode("gray.600", "gray.400")}>
          {extras}
        </Text>
      </Box>

      <Button
        mt="4"
        mb="8"
        size="lg"
        fontSize="md"
        colorScheme={c}
        onClick={onClick}
      >
        {props.buttonCopy}
      </Button>

      <Box>
        <Text fontWeight="bold" mb="4">
          What you get:
        </Text>
        <List spacing="4">
          {features.map((feature, index) => (
            <PricingDetail key={index} iconColor={mode(`${c}.500`, `${c}.200`)}>
              {feature}
            </PricingDetail>
          ))}
        </List>
      </Box>
    </Box>
  )
}
