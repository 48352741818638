import {
  Box,
  Button,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react"
import * as React from "react"
import { DurationSwitcher } from "./DurationSwitch"
import { PricingCard } from "./PricingCard"
import vomuxLogo from "../../images/vomux_logo.svg"
import { navigate } from "gatsby"
import { sendAmplitudeEvent } from "../../../services/amplitude"
import { AnalyticsEvents } from "../../../services/types"

export const InterviewPricing = () => {
  return (
    <Box as="section" py="8">
      <Box
        maxW={{ base: "xl", md: "5xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <Flex
          direction="column"
          align={{ base: "flex-start", md: "center" }}
          maxW="2xl"
          mx="auto"
        >
          <Heading
            as="h1"
            size="2xl"
            letterSpacing="tight"
            fontWeight="extrabold"
            textAlign={{ base: "start", md: "center" }}
          >
            Find the perfect plan for you
          </Heading>
        </Flex>
        <Flex direction={{ base: "column", lg: "row" }}>
          <Flex
            direction={{ base: "column", lg: "row" }}
            maxW={{ base: "560px", lg: "unset" }}
            mx="auto"
            mt="10"
            bg="white"
            rounded="xl"
          >
            <PricingCard
              flex="1"
              colorScheme="blue"
              name="Developer"
              description="Interview candidates live in our collaborative editor."
              price="$39"
              priceStrike={true}
              duration="Per month"
              extras="During Beta"
              buttonCopy="Get access"
              features={[
                "25 interviews per month",
                "Collaborative coding editor",
                "Run code for 7+ languages",
              ]}
              onClick={() => {
                sendAmplitudeEvent(
                  AnalyticsEvents.landing_page_signup_clicked,
                  {
                    button: "Get access",
                  }
                )
                navigate("/signup")
              }}
            />
          </Flex>
          <Flex
            direction={{ base: "column", lg: "row" }}
            maxW={{ base: "560px", lg: "unset" }}
            mx="auto"
            mt="10"
            bg={mode("white", "gray.700")}
            rounded="xl"
          >
            <PricingCard
              flex="1"
              colorScheme="blue"
              name="Startup"
              description="Our experts conduct technical interviews for you."
              price="Custom"
              priceStrike={false}
              duration="Per technical interview"
              extras="Custom per interview design"
              buttonCopy="Start now"
              features={[
                "Expert interviewers evaluating your candidates",
                "Standardized rubrics to bring into your process",
                "Custom interview questions for your role",
              ]}
              onClick={() => {
                sendAmplitudeEvent(
                  AnalyticsEvents.landing_page_hire_clicked,
                  {}
                )
                if (typeof window !== "undefined") {
                  window.open("https://calendly.com/vomux/interview")
                }
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}
