import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  LightMode,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import * as React from "react"
import { COLORS_PRIMARY } from "../../shared/vomux_colors"
import { navigate } from "gatsby"
import { sendAmplitudeEvent } from "../../../services/amplitude"
import { AnalyticsEvents } from "../../../services/types"

export const Hero = () => {
  return (
    <Box>
      <Box as="section" color={COLORS_PRIMARY.DARK_BLUE} py="3rem">
        <Box
          maxW={{ base: "xl", md: "5xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Box textAlign="center">
            <Heading
              as="h1"
              size="3xl"
              fontWeight="extrabold"
              maxW="48rem"
              mx="auto"
              lineHeight="1.2"
              letterSpacing="tight"
            >
              Screen software engineers with confidence
            </Heading>
            <Text fontSize="xl" mt="4" maxW="xl" mx="auto">
              Interview engineers with our{" "}
              <strong>collaborative coding editor</strong> or use our{" "}
              <strong>expert interviewers</strong> to conduct them for you.
            </Text>
          </Box>

          <Stack
            justify="center"
            direction={{ base: "column", md: "row" }}
            mt="10"
            spacing="4"
          >
            <LightMode>
              <VStack spacing={4}>
                <Button
                  as="a"
                  href="#"
                  size="lg"
                  w={{ base: 48, md: 56 }}
                  colorScheme="blue"
                  px="8"
                  fontWeight="bold"
                  fontSize="md"
                  onClick={() => {
                    sendAmplitudeEvent(
                      AnalyticsEvents.landing_page_signup_clicked,
                      {
                        button: "Get started today",
                      }
                    )
                    navigate("/signup")
                  }}
                >
                  Get started today
                </Button>
                <Button
                  colorScheme="blue"
                  w={{ base: 48, md: 56 }}
                  flexShrink={0}
                  fontSize="md"
                  onClick={() => {
                    sendAmplitudeEvent(
                      AnalyticsEvents.landing_page_login_clicked,
                      {}
                    )
                    navigate("/login")
                  }}
                >
                  Login
                </Button>
              </VStack>
              {/* <Button
                as="a"
                href="#"
                size="lg"
                colorScheme="whiteAlpha"
                px="8"
                fontWeight="bold"
                fontSize="md"
              >
                Request demo
              </Button> */}
            </LightMode>
          </Stack>

          {/* <Box
            className="group"
            cursor="pointer"
            position="relative"
            rounded="lg"
            overflow="hidden"
          >
            <Img
              alt="Screenshot of Envelope App"
              src="https://res.cloudinary.com/chakra-ui-pro/image/upload/v1621085270/pro-website/app-screenshot-light_kit2sp.png"
            />
            <Circle
              size="20"
              as="button"
              bg="white"
              shadow="lg"
              color="blue.600"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate3d(-50%, -50%, 0)"
              fontSize="xl"
              transition="all 0.2s"
              _groupHover={{
                transform: 'translate3d(-50%, -50%, 0) scale(1.05)',
              }}
            >
              <VisuallyHidden>Play demo video</VisuallyHidden>
              <FaPlay />
            </Circle>
          </Box> */}
        </Box>
      </Box>
      {/* 
      <Box as="section" py="24">
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
          <Text
            fontWeight="bold"
            fontSize="sm"
            textAlign="center"
            textTransform="uppercase"
            letterSpacing="wide"
            color={mode('gray.600', 'gray.400')}
          >
            Trusted by over 6,000 blues
          </Text>
          <SimpleGrid
            mt="8"
            columns={{ base: 1, md: 2, lg: 6 }}
            color="gray.500"
            alignItems="center"
            justifyItems="center"
            spacing={{ base: '12', lg: '24' }}
            fontSize="2xl"
          >
            <Logos.ChatMonkey />
            <Logos.Wakanda />
            <Logos.Lighthouse />
            <Logos.Plumtic />
            <Logos.WorkScout />
            <Logos.Finnik />
          </SimpleGrid>
        </Box>
      </Box> */}
    </Box>
  )
}
