import {
  BsCaretRightSquareFill,
  BsPersonLinesFill,
  BsTerminalFill,
} from "react-icons/bs"

export const features = [
  {
    name: "Collaborative Coding Editor",
    description:
      "An editor in your browser to code with your candidates in real-time.",
    icon: BsTerminalFill,
  },
  {
    name: "Code Runner",
    description:
      "Our system supports running code during your interview for 7+ languages.",
    icon: BsCaretRightSquareFill,
  },
  {
    name: "Expert Technical Interviewers",
    description:
      "A team of expert interviewers available to design interview questions and screen candidates for your role.",
    icon: BsPersonLinesFill,
  },
]
