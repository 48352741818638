import { Box } from "@chakra-ui/react"
import React from "react"
import { AnalyticsEvents } from "../../services/types"
import { Features } from "./features/App"
import { Footer } from "./footer/App"
import { Hero } from "./hero/App"
import { InterviewPricing } from "./interview_pricing/App"
import { MobileHeader } from "./header"
import { useDelayedAnalyticsEventOnLand } from "../../hooks/analytics"
import { COLORS_PRIMARY } from "../shared/vomux_colors"

export const LandingPage = () => {
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.landing_page_landed)

  return (
    <Box w="100vw" bgColor={COLORS_PRIMARY.BACKGROUND_BLUE}>
      <MobileHeader />
      <Hero />
      <Features />
      <InterviewPricing />
      <Footer />
    </Box>
  )
}
