import {
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import React from "react"
import { navigate } from "gatsby"
import VomuxLogo from "../../images/vomux_logo.svg"
let url: string | string[] =
  typeof window !== "undefined" ? window.location.href : ""
url = url.split("/")

export const MobileHeader = () => {
  return (
    <Flex direction="column" w="100vw">
      <Center mt="2rem">
        <Image
          w="156px"
          display={url[url.length - 1] === "interview" ? "none" : "flex"}
          // width={{ md: "10rem", base: "7rem" }}
          src={VomuxLogo}
        />
      </Center>
    </Flex>
  )
}

export const DesktopLandingHeader = () => {
  return (
    <Flex pt="2rem" direction={{ sm: "column", md: "row" }} w="100vw">
      <Image
        ml={"4rem"}
        display={url[url.length - 1] === "interview" ? "none" : "flex"}
        width={{ md: "10rem", base: "7rem" }}
        src={VomuxLogo}
      />
      <Text
        onClick={() => {
          typeof window !== "undefined"
            ? window.scrollTo({ top: 100, behavior: "smooth" })
            : undefined
        }}
        cursor="pointer"
        fontSize="20"
        mt="0.8rem"
        ml="4rem"
      >
        Features
      </Text>
      <Text cursor="pointer" fontSize="20" mt="0.8rem" ml="3rem">
        Pricing
      </Text>
      <Spacer />
      <Button
        colorScheme="blue"
        size="lg"
        mt={{ base: "6", md: "0" }}
        mr={{ lg: "2rem" }}
        w={{ base: "full", md: "auto" }}
        minW="10rem"
        flexShrink={0}
        fontSize="md"
        onClick={() => {
          navigate("/login")
        }}
      >
        Login
      </Button>
    </Flex>
  )
}
